import React from "react";
import { Image, ToggleButton, ToggleButtonGroup } from "@aws-amplify/ui-react";
import { VALID_MATCH_TYPES } from "../../constants";
import { GAME_TYPE_ARIA_LABELS, GAME_TYPE_ICONS, ICON_REVERSE_PERCENTAGE_BY_MODE } from "../constants";

function capitalize(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function SingleDoublesButton({matchType, setMatchType, colorMode}) {

    return (
        <ToggleButtonGroup
            color='primary'
            value={matchType}
            size='small'
            isExclusive={true}
            onChange={(newValue) => setMatchType(newValue || matchType)}>
            {VALID_MATCH_TYPES.map(type => (
                <ToggleButton key={type} value={type}>
                    <Image ariaLabel={GAME_TYPE_ARIA_LABELS[type]}
                            src = {GAME_TYPE_ICONS[type]}
                            height={"1rem"}
                            marginRight={"5px"}
                            style={{filter: `invert(${ICON_REVERSE_PERCENTAGE_BY_MODE[colorMode][type!==matchType]}%)`}}
                            alt={type}/>
                            {capitalize(type)}
                    </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}