import {
    DATE,
    MATCH_TYPE,
    SPORT_TYPE,
    VALID_MATCH_TYPES,
    VALID_SPORT_TYPES,
    VIDEO_URL,
} from "./Uploader/constants";

import { getInfo } from "react-mediainfo";

export function isStateReady(state, files) {
    return (
        VALID_MATCH_TYPES.includes(state[MATCH_TYPE]) &&
        state[DATE] <= new Date() &&
        state[VIDEO_URL] !== null &&
        files.every((file) => file.thumbnail)
    );
}

export function isTypeSelected(state) {
    return VALID_MATCH_TYPES.includes(state[MATCH_TYPE]);
}

export function isSportSelected(state) {
    return VALID_SPORT_TYPES.includes(state[SPORT_TYPE]);
}

export function cleanVideo(files, setFiles, only_first_file = false) {
    if (files.length)
        if (only_first_file) {
            URL.revokeObjectURL(files[0].preview);
            files = files.slice(1);
        } else {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
            files = [];
        }
    setFiles(files);
    return files;
}

export async function getVideoInfo(videoFile) {
    const allInfo = await getInfo(videoFile);
    const videoInfo = allInfo.media.track.find(
        (track) => track["@type"] === "Video"
    );
    const info = {
        width: parseInt(videoInfo.Width),
        height: parseInt(videoInfo.Height),
        frameRate: parseFloat(videoInfo.FrameRate),
    };
    return info;
}
