import React, { useMemo } from "react";
import { flushSync } from "react-dom";
import { useDropzone } from "react-dropzone";
import { getVideoInfo } from "../utils";
import {
    DRAG_N_DROP_TEXT,
    MAX_FILE_SIZE,
    MAX_VIDEO_W,
    MIN_VIDEO_W,
    MAX_VIDEO_H,
    MIN_VIDEO_H,
    MIN_VIDEO_FPS,
    MAX_VIDEO_FPS,
} from "./constants";
import {
    baseStyle,
    focusedStyle,
    acceptStyle,
    rejectStyle,
} from "./uploaderStyles";

export function Uploader({ setFiles, alerts, setAlerts }) {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: { "video/*": [] },
        maxSize: MAX_FILE_SIZE,
        getFilesFromEvent: async (event) =>
            myCustomFileGetter(event, alerts, setAlerts),
        // Charge the preview image when the file is dropped and add it to the acceptedFiles object
        onDrop: (acceptedFiles) => {
            setFiles(acceptedFiles.map((file) => addFilePreview(file)));
        },
        // Remove the preview image when the file is removed
        onDropRejected: (rejectedFiles) => {
            rejectedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
            console.log("REJECTED", rejectedFiles);
        },
    });

    let style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <section className='container'>
            {/* That div is the one where the style will be applied */}
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>{DRAG_N_DROP_TEXT}</p>
            </div>
        </section>
    );
}

function addFilePreview(file) {
    Object.assign(file, { preview: URL.createObjectURL(file) });
    return file;
}

async function myCustomFileGetter(event, alerts, setAlerts) {
    let files = [];
    // If event is of type SyntheticEvent, then it is a drop event
    if (event.dataTransfer) {
        const fileList = event.dataTransfer
            ? event.dataTransfer.files
            : event.target.files;
        for (let i = 0; i < fileList.length; i++) files.push(fileList.item(i));
        // Otherwise, it is a click event
    } else {
        for (const file of event) files.push(await file.getFile());
    }

    // getVideoInfo for each file
    files = await Promise.all(
        files.map(async (file) => {
            const info = await getVideoInfo(file);
            return Object.assign(file, info);
        })
    );

    //Keep only the files that are acceptable
    files = files.filter((file) => isFileAcceptable(file, alerts, setAlerts));

    return files;
}

function isFileAcceptable(file, alerts, setAlerts) {
    // Generate a random id for the alert
    let videoID = `${file.name}-${Math.floor(Math.random() * 1000)}`;
    let errorMsg = "";
    if (file.size > MAX_FILE_SIZE)
        errorMsg += `Max file size of ${
            MAX_FILE_SIZE / 1024 ** 3
        }GB exceeded (It occupies ${file.size / 1024 ** 3}GB). `;

    if (
        file.width < MIN_VIDEO_W ||
        file.height < MIN_VIDEO_H ||
        file.width > MAX_VIDEO_W ||
        file.height > MAX_VIDEO_H
    )
        errorMsg += `Video dimensions must be between ${MIN_VIDEO_W}x${MIN_VIDEO_H} and ${MAX_VIDEO_W}x${MAX_VIDEO_H}. (It is ${file.width}x${file.height})`;

    if (file.frameRate < MIN_VIDEO_FPS || file.frameRate > MAX_VIDEO_FPS)
        errorMsg += `This video can't be processed by Clutch. Video frame rate must be between ${MIN_VIDEO_FPS} and ${MAX_VIDEO_FPS}. (Your video is ${file.frameRate}FPS)`;

    if (errorMsg !== "") {
        //errorMsg = `Video ${file.name} is not valid: ${errorMsg}`;
        const newEntry = { [videoID]: { type: "error", message: errorMsg } };
        setAlerts({ ...alerts, ...newEntry });
        // That's a very ugly trick just to make setState work synchronously
        alerts[videoID] = { type: "error", message: errorMsg };
        return false;
    }

    return true;
}
