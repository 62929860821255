export const HEADER_STYLE_PROPS = {
    direction:'row',
    justifyContent:'flex-end',
    alignItems:'stretch',
    alignContent:'flex-end',
    wrap:'wrap',
    gap:'1rem'
}

export const RIGHT_HEADER_PROPS = {
    direction:'row',
    justifyContent:'flex-start',
    alignItems:'center',
    alignContent:'flex-start',
    wrap:'wrap',
    gap:'1rem'
}

export const STYLE = {
    marginBottom:'1rem',
}