import React, { useEffect, useState } from "react";
import { Button, Alert } from "@aws-amplify/ui-react";
import { cleanVideo, isStateReady, isTypeSelected, isSportSelected } from "../utils";

import { VIDEO_URL, DISMISS_ALERTS_AFTER, SPORT_TYPE, MATCH_TYPE, INITIAL_STATE } from "../Uploader/constants";
import { SEND_TEXT } from "./constants";

import { UploadProgressBar } from "./uploadProgressBar";
import {
    sendVideoToUserBucket,
} from "../../communication/amplify";

import { createDBMetaDataEntry, userIsBlocked, updateVideoMetaDataToUploaded } from "../../communication/supabase";

import "./icon.css";

export function SendButton({ state, files, needsTranscoding, setFiles, setIsUploading, user, setMatchType}) {
    const isReady = isStateReady(state, files);
    const matchTypeSelected = isTypeSelected(state);
    const sportTypeSelected = isSportSelected(state);
    // Set up the authenticated storage for this user
    const [uploadProgress, setUploadProgress] = useState(null);
    const [userBlocked, setUserBlocked] = useState(true);

    // Set up the is Uploading state to match the upload progress
    useEffect(
        () => setIsUploading(uploadProgress !== null),
        [uploadProgress, setIsUploading]
    );
    // When the isUserBlocked promise is resolved, set the userBlocked state
    useEffect(() => {
        userIsBlocked(user, state).then((isBlocked) => setUserBlocked(isBlocked));
    }, [user, state]);
    const [showingSuccessAlert, setShowingSuccessAlert] = useState(false);
    useEffect(() => {
        if (showingSuccessAlert && DISMISS_ALERTS_AFTER > 0)
            setTimeout(
                () => setShowingSuccessAlert(false),
                DISMISS_ALERTS_AFTER
            );
    }, [showingSuccessAlert]);

    let title = SEND_TEXT


    if (!sportTypeSelected) {
        title="You must select a Sport! (badminton, tennis, padel)"
    } 
    else if (!matchTypeSelected) {
    title="You must select a match type (singles/doubles)"

    }
    else if (userBlocked) {
        title="Sign up to clutch pro in the iOS App to start uploading video"
    }

    return (
        <>
            {uploadProgress === null? (
                <Button
                    isFullWidth={true}
                    variation='primary'
                    ariaLabel='Send Video to Clutch'
                    isLoading={!isReady || userBlocked || !matchTypeSelected || !sportTypeSelected}
                    // Show a message on hover
                    title={title}
                    onClick={() =>
                        sendVideo(
                            state,
                            user,
                            files,
                            needsTranscoding,
                            setFiles,
                            setUploadProgress,
                            setShowingSuccessAlert,
                            setMatchType
                        )
                    }>
                    {title}
                </Button>
            ) : (
                <UploadProgressBar currentProgress={uploadProgress} />
            )}
            {showingSuccessAlert && (
                <Alert
                    variation='success'
                    heading='Video Uploaded Successfully'
                    isDismissible={true}
                    hasIcon={true}
                    onDismiss={() => setShowingSuccessAlert(false)}
                />
            )}
        </>
    );
}

async function sendVideo(
    state,
    user,
    files,
    needsTranscoding,
    setFiles,
    setUploadProgress,
    setShowingSuccessAlert,
    setMatchType
) {
    const video_objects = state[VIDEO_URL];
    if (video_objects.length !== files.length)
        throw new Error(
            "Number of videos in state does not match number of videos in files"
        );
    for (const video of video_objects) {
        setUploadProgress({ loaded: 0, total: 1 });
        const {video_id, identity_id} = await createDBMetaDataEntry(
            state,
            user,
            video.name
        );
        console.log("Got video id and identity id: ", video_id, identity_id)
        const video_extension = video.name.split(".").pop().toLowerCase();
        let transcode = needsTranscoding;
        // Files with another format than mp4 always need to be transcoded, no matter if the box is unchecked.
        if (video_extension !== "mp4") {
            transcode = true;
        }
        await sendVideoToUserBucket(video, video_id, identity_id, setUploadProgress, transcode);
        await updateVideoMetaDataToUploaded(user, video_id, transcode);

        files = cleanVideo(files, setFiles, true);
    }
    setUploadProgress(null);
    setShowingSuccessAlert(true);
    setMatchType(INITIAL_STATE[MATCH_TYPE])

}
