import { UploadPage } from "./components/uploadPage";
import { Amplify } from "aws-amplify";
import {
    ThemeProvider,
    withAuthenticator,
    View,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
import { Storage } from "aws-amplify";
//import './styles.css';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useState } from "react";
import { DEFAULT_COLOR_MODE, LIGHT, VALID_COLOR_MODES } from "./appConstants";
import { DARK_MODE_BACKGROUND, LIGHT_MODE_BACKGROUND, THEME } from "./components/theme";

Amplify.configure(awsExports);

const endPoint = {
    "aws_appsync_graphqlEndpoint":  "https://3kko6x4b45em7agshe4epeu63q.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-n24trmgkwnglhhn7fdqc7mheqy"
}

Amplify.configure(endPoint);

function App({ signOut, user }) {
    const [colorMode, setColorMode] = useState(DEFAULT_COLOR_MODE);
    if (!VALID_COLOR_MODES.includes(colorMode))
        throw new Error(`Invalid color mode: ${colorMode}`);    

    const bodyStyle = `background-color: ${
        colorMode === LIGHT ? LIGHT_MODE_BACKGROUND : DARK_MODE_BACKGROUND
    }`;
    Storage.configure({ level: "protected" });
    return (
        <ThemeProvider colorMode={colorMode} theme={THEME}>
            <HelmetProvider>
                <Helmet bodyAttributes={{ style: bodyStyle }} />
                <View className='App'>
                    <UploadPage signOut={signOut} user={user} colorMode={colorMode} setColorMode={setColorMode} />
                </View>
            </HelmetProvider>
        </ThemeProvider>
    );
}
// Disable the create account button
const withAuthenticatorConfig = {
    hideSignUp: true,
    header: "Sign in to Clutch", // Doesn't seem to work
};

export default withAuthenticator(App, withAuthenticatorConfig);
