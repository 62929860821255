/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://v7do722burcvbb7unf25w6hkbm.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-rzhm6azmdnf55lycmxekgzcxdq",
    "aws_cognito_identity_pool_id": "eu-central-1:7c5a7023-e2d8-4109-b4aa-943deebe2ce7",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_0X7okUIho",
    "aws_user_pools_web_client_id": "1n7be85s96obbg22bf7u3n35av",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "User-25ihdmsfwbdjrfig3ka6rmvfmu-production",
            "region": "eu-central-1"
        },
        {
            "tableName": "VideoMetadata-25ihdmsfwbdjrfig3ka6rmvfmu-production",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "clutchvideostorageios162404-production",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
