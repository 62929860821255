import { Flex, useBreakpointValue } from "@aws-amplify/ui-react";
import { baseStyle } from "../uploaderStyles";
import { PERCENTAGES_BY_BREAKPOINT, SCREENSHOT_TIME } from "./constants";
import { VideoPlayer } from "./videoPlayer";

const SCALE_PERCENTAGE = 30;

export function BulkPreview({ files, setFiles }) {
    const percentage = useBreakpointValue(PERCENTAGES_BY_BREAKPOINT);
    const { /*scale,*/ gap } = getCurrentScaleAndGap(percentage);
    // Put all the react players in the same row
    return (
        <div style = {baseStyle}>
            <Flex wrap="wrap" alignItems="center" justifyContent="left" gap={gap}>
            {files.map((file, i) => 
            (
                <VideoPlayer key = {file.name} file={file} screenshotTime={SCREENSHOT_TIME*2} files={files} setFiles={setFiles}/>
            ))}
            </Flex>
        </div>
    );
}

function getCurrentScaleAndGap(scale_percentage = SCALE_PERCENTAGE){
    const gap_percentage = (100%SCALE_PERCENTAGE)/Math.floor(100/SCALE_PERCENTAGE);
    return {
        scale: `${scale_percentage}%`,
        gap: `${gap_percentage}%`
    };

}