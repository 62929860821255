// ---------------------------- TEXT ----------------------------
export const DRAG_N_DROP_TEXT =
    "Drag 'n' drop your video here, or click to select files";
export const DELETE_VIDEO_TEXT = "Cancel Video";
export const GUIDELINES_HREF =
    "https://clutchapp.notion.site/Recording-guidelines-5f561446f41a47b6a0f7dcde6f9663e3";
export const GUIDELINES_TITLE = "Recording Guidelines";
export const GUIDELINES_PRETEXT =
    "To make sure your video gets processed successfully, please check out our";
export const GUIDELINES_HREF_TEXT = "video recording guidelines";

export const INSTRUCTIONS_TITLE = "How it works";
export const GUIDELINES = [
    "Video should be 720p or 1080p.",
    "Video should have 25-30 FPS.",
    "Match should be recorded from a steady angle, ie. not handheld or with changing camera angles like TV-broadcast.",
    "Match should be recorded from a high angle behind the court.",
    "Full court should be in view."
];
export const INSTRUCTIONS_TEXT =
    "Once your match has been uploaded successfully it will appear in the video section of your Clutch profile. Your match will appear as a grey thumbnail with a download button that you can tap on. " +
    "Note that it may take up to an hour before you see uploads from the web in your app";

export const VALID_MATCH_TYPES = ["singles", "doubles"];
export const VALID_SPORT_TYPES = ["badminton", "tennis", "padel"];

// --------------- COLORS ---------------
export const DANGER_COLOR = "#FF5C00";
export const DANGER_ON_HOVER_COLOR = "#FF8E4F";
export const WARNING_COLOR = "#F0AD4E";

// ---------- STATE ATTRIBUTES ----------
export const MATCH_TYPE = "matchType";
export const SPORT_TYPE = "sportType";
export const TEAM_ID = "team_id";
export const TEAM_LIST = "teamList";
export const VIDEO_URL = "videoUrl";
export const DATE = "date";

export const INITIAL_STATE = {
    [TEAM_ID]: null,
    [TEAM_LIST]:[],
    // [MATCH_TYPE]: VALID_MATCH_TYPES[0],
    [MATCH_TYPE]: null,
    [SPORT_TYPE]: null,
    [VIDEO_URL]: null,
    [DATE]: new Date(),
};

// -------- VIDEO CONSTRAINTS --------

export const MAX_FILE_SIZE = 3 * 1024 ** 3;

export const MIN_VIDEO_H = 720;
export const MIN_VIDEO_W = 1280;
export const MAX_VIDEO_H = 1080;
export const MAX_VIDEO_W = 1920;

export const MIN_VIDEO_FPS = 23;
export const MAX_VIDEO_FPS = 33;

// -------------- OTHER --------------
export const DISMISS_ALERTS_AFTER = -1;

export const DISMISS_WRONG_ALERTS_AFTER = 5000;
