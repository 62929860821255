import { defaultDarkModeOverride } from "@aws-amplify/ui-react";
import { DARK, LIGHT } from "../appConstants";

export const DARK_MODE_BACKGROUND = "#00351A";
export const LIGHT_MODE_BACKGROUND = "#F6FFED";
export const LOADER_BAR_COLOR = "#4BFF00";
export const ALERT_SUCCESSFUL_COLOR = "#00AF54";
export const BUTTON_BACKGROUND_DISABLES = "#ABABAB";
export const BUTTON_PRIMARY_BACKGROUND = "#4BFF00";
export const BUTTON_PRIMARY_ON_HOVER = "#CFFF68";
export const FONT_COLOR = "#121317";
export const UPLOAD_SUCCESSFUL_COLOR_MSG = LIGHT_MODE_BACKGROUND;
export const BUTTON_FONT_COLOR = FONT_COLOR;

export const THEME = {
    name: "clutch-theme",
    overrides: [
        defaultDarkModeOverride,
        {
            colorMode: DARK,
            tokens: {
                colors: {
                    background: {
                        primary: {
                            value: DARK_MODE_BACKGROUND,
                        },
                    },
                },
                components: {
                    loader: {
                        strokeFilled: {
                            value: LOADER_BAR_COLOR,
                        },
                    },
                    alert: {
                        success: {
                            color: {
                                value: DARK_MODE_BACKGROUND,
                            },
                            backgroundColor: {
                                value: UPLOAD_SUCCESSFUL_COLOR_MSG,
                            }
                        },
                    },
                    button: {
                        primary: {
                            color: {
                                value: DARK_MODE_BACKGROUND,
                            },
                        },
                    },
                },
            },
        },
        {
            colorMode: LIGHT,
            tokens: {
                colors: {
                    background: {
                        primary: {
                            value: LIGHT_MODE_BACKGROUND,
                        },
                    },
                },
            },
        },
    ],
    tokens: {
        colors: {
            font: {
                primary: {
                    value: FONT_COLOR,
                },
            },
        },
        fonts: {
            default: {
                static: {
                    value: "DM Sans",
                },
                variable: {
                    value: "DM Sans",
                },
            },
        },
        components: {
            loader: {
                strokeFilled: {
                    value: LOADER_BAR_COLOR,
                },
            },
            button: {
                primary: {
                    backgroundColor: {
                        value: BUTTON_PRIMARY_BACKGROUND,
                    },
                    color: {
                        value: BUTTON_FONT_COLOR,
                    },
                    _hover: {
                        backgroundColor: {
                            value: BUTTON_PRIMARY_ON_HOVER,
                        },
                        color: {
                            value: BUTTON_FONT_COLOR,
                        },
                    },
                },
            },
        },
    },
};
