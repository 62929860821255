import React, { createRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useScreenshot } from "use-react-screenshot";
import { SCREENSHOT_TIME } from "./constants";

export function VideoPlayer({
    file,
    files,
    setFiles,
    screenshotTime = SCREENSHOT_TIME,
}) {
    // setFiles and files must be received to update the state consistently
    if (!typeof setFiles === "function")
        throw new Error("setFiles must be a callback");
    else if (!Array.isArray(files)) throw new Error("files must be an array");
     // eslint-disable-next-line no-unused-vars
    const [image, takeScreenshot] = useScreenshot({
        type: "image/jpeg",
    });
    const ref = createRef();

    const getImage = React.useCallback(
        () =>
            takeScreenshot(ref.current).then((image) =>
                saveThumbnail(image, file, files, setFiles)
            ),
        [ref, file, files, setFiles, takeScreenshot]
    );

    const [screenshotTaken, setScreenshotTaken] = useState(false);
    //Take the screenshot in N miliseconds
    useEffect(() => {
        setTimeout(() => {
            if (!screenshotTaken && ref.current !== null) {
                getImage();
                setScreenshotTaken(true);
            }
        }, screenshotTime);
    }, [ref, screenshotTaken, screenshotTime, getImage, setScreenshotTaken]);

    return (
        // A wrapper div that have the same size as the video
        <div
            ref={ref}
            key={file.name}
            style={{
                width: "fit-content",
                height: "fit-content",
                margin: "auto",
            }}>
            <ReactPlayer
                key={file.name}
                url={file.preview}
                width={"100%"}
                controls={screenshotTaken}
                style={{ marginBottom: "0.25rem" }}
            />
        </div>
    );
}

function saveThumbnail(image, file, files, setFiles) {
    if (file["thumbail"] !== undefined) {
        throw new Error("File already has a thumbnail");
    }

    file["thumbnail"] = image;
    // Update the state with the new file
    setFiles([...files]);
}
