import { Storage } from "aws-amplify";
import { createFileName } from "use-react-screenshot";

const THUMBNAIL_BUCKET = "video-thumbnail";
const COURT_THUMBNAIL_BUCKET = "court_detection"

export async function sendVideoToUserBucket(
  video_object,
  id,
  identity_id,
  progressCallback,
  transcode
) {
  console.log("Uploading video: ", video_object);
  const video_extension = video_object.name.split(".").pop().toLowerCase();
  const video_id = `${id}.${video_extension}`;
  //Get the content of the actual thumbnail bucket
  Storage.configure({ level: "public" });
  const bucket_config = {
    contentType: video_object.type,
    resumable: false,
    progressCallback: (progress) => progressCallback(progress),
  };

  let path_to_video;
  if (transcode) {
    path_to_video = `${identity_id}/webapp/${video_id}`;
  } else {
    path_to_video = `${identity_id}/${video_id}`;
  }

  // Upload video to bucket
  const result = await Storage.put(path_to_video, video_object, bucket_config);
  console.log("Video upload result: ", result);

  const thumbnail_id = `${id}.png`;
  const thumbnail = await getThumbnail(video_object, thumbnail_id);
  Storage.configure({ level: "public" });
  const thumbnail_bucket_config = {
    contentType: thumbnail.type,
    resumable: false,
  };
  //Upload thumbnail to bucket
  const thumbnail_result = await Storage.put(
    `${THUMBNAIL_BUCKET}/${thumbnail_id}`,
    thumbnail,
    thumbnail_bucket_config
  );

  console.log(video_object)
  const court_thumbnail_results = await uploadCourtThumbnails(video_object, id)


  Storage.configure({ level: "public" });
  // return [result, thumbnail_result];
  return [result, thumbnail_result, court_thumbnail_results];
}

export async function uploadCourtThumbnails(video_object, video_metadata_id) {
  const court_thumbnails = await getCourtThumbnails(video_object, video_metadata_id);
  Storage.configure({ level: "public" });
  const thumbnail_bucket_config = {
    contentType: "image/jpeg",
    resumable: false,
  };

  const uploadPromises = court_thumbnails.map(async (thumbnail) => {
    try {
      return await Storage.put(
        `${COURT_THUMBNAIL_BUCKET}/${thumbnail.name}`,
        thumbnail,
        thumbnail_bucket_config
      );
    } catch (error) {
      console.error(`Failed to upload ${thumbnail.name}`, error);
      throw error;  // or handle it differently depending on your needs
    }
  });

  return await Promise.all(uploadPromises);
}

export async function getThumbnail(video_object, filename) {
  // Generate a thumbnail from the video
  if (!video_object.thumbnail)
    throw new Error("Video object does not have a thumbnail");

  const img = new Image();
  img.src = video_object.thumbnail;

  // Await until the image loads
  await new Promise((resolve) => {
    img.onload = resolve;
  });

  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);

  // Use "image/png" to save as PNG
  const dataURL = canvas.toDataURL("image/png");
  const blob = await fetch(dataURL).then((r) => r.blob());
  const extension = "png"; // Change the extension to PNG

  // Get the base name of the filename (filename is like xxxx.png)
  filename = filename.split(".").shift();

  const thumbnail_file = new File([blob], createFileName(extension, filename), {
    type: "image/png", // MIME type is PNG
  });
  return thumbnail_file;
}

export async function getCourtThumbnails(video_object, base_filename) {
  if (!video_object.preview) throw new Error("Video object does not have a preview");

  const video = document.createElement('video');
  video.src = video_object.preview;

  await new Promise(resolve => {
      video.onloadedmetadata = resolve;
  });

  const totalDuration = video.duration;
  const thumbnails = [];

  for (let i = 0; i < 10; i++) {
      const randomTime = Math.random() * totalDuration;
      const thumbnail = await captureFrame(video, randomTime);
      const filename = `${base_filename}-${i}.jpg`;
      thumbnails.push(new File([thumbnail], filename, { type: "image/jpeg" }));
  }

  return thumbnails;
}

async function captureFrame(video, time) {
  return new Promise(async resolve => {
      video.currentTime = time;
      video.onseeked = async () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const dataURL = canvas.toDataURL('image/jpeg');
          const blob = await fetch(dataURL).then(r => r.blob());
          resolve(blob);
      };
  });
}
