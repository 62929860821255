import { ToggleButton , ToggleButtonGroup} from "@aws-amplify/ui-react";
import { DARK, LIGHT } from "../../appConstants";
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";

export function ThemeToggle({colorMode, setColorMode}) {
    return (
        <ToggleButtonGroup
          value={colorMode}
          variation="small"
          isExclusive
          onChange={(newValue) => setColorMode(newValue || colorMode)}>
            <ToggleButton value={LIGHT}>
                <MdOutlineLightMode />
            </ToggleButton>
            <ToggleButton value={DARK}>
                <MdOutlineDarkMode />
            </ToggleButton>
        </ToggleButtonGroup>
    );
}