import React from 'react';
import { Loader } from "@aws-amplify/ui-react";
import { useTheme } from "@aws-amplify/ui-react";

export function UploadProgressBar({currentProgress}) {
    //Asserts that the currentProgress is a valid progress object (contains loaded and total)
    if(currentProgress.loaded === undefined || currentProgress.total === undefined){
        throw new Error(`Invalid progress object:`);
    }
    const {tokens} = useTheme();
    const percentage = (currentProgress.loaded / currentProgress.total) * 100;

    return (
        <Loader variation='linear' percentage={percentage} isDeterminate isPercentageTextHidden
        paddingLeft={10} paddingRight={10}
        filledColor={tokens.components.loader.strokeFilled.value} />
    );
}