import { createClient } from "@supabase/supabase-js";
import {
  publicSupabaseUrl,
  publicSupabaseAnonKey,
  SupabaseJWTSignKey,
} from "./constants.js";
import * as jose from "jose";
import { v4 as uuidv4 } from "uuid";
import { getAccessToken } from "./cognito.js";
import {
  MATCH_TYPE,
  SPORT_TYPE,
  TEAM_ID,
  VALID_MATCH_TYPES,
} from "../components/Uploader/constants";
import { READY_FOR_TRANSCODING, UPLOADED } from "./constants";

const getSupabase = (access_token) => {
  const options = {};

  if (access_token) {
    options.global = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
  }

  const supabase = createClient(
    publicSupabaseUrl,
    publicSupabaseAnonKey,
    options
  );

  return supabase;
};

async function getSupabaseFromCognitoUser(user) {
  const accessToken = getAccessToken(user);
  const supabaseToken = await generateSupabaseJWTwithId(accessToken.sub);
  return getSupabase(supabaseToken);
}

async function generateSupabaseJWTwithId(id) {
  const payload = {
    sub: id,
    exp: Math.floor(Date.now() / 1000) + 60 * 60,
  };
  const token = await new jose.SignJWT(payload)
    .setProtectedHeader({ alg: "HS256" })
    .sign(new TextEncoder().encode(SupabaseJWTSignKey));
  return token;
}

export async function createDBMetaDataEntry(state_metadata, user, filename) {
  //Query for anything to check everything is connected
  const video_metadata = build_video_metadata_from_state(
    state_metadata,
    user.attributes.sub,
    filename
  );

  const supabase = await getSupabaseFromCognitoUser(user);

  await supabase.from("VideoMetadata").insert(video_metadata);

  const { data, error } = await supabase
    .from("User")
    .select("identityID")
    .eq("id", user.attributes.sub);

  console.log("fetched identity data from supabase", data);

  return { video_id: video_metadata.id, identity_id: data[0].identityID };
}

export function build_video_metadata_from_state(user_state, user_id, filename) {
  const game_type = user_state[MATCH_TYPE];
  const team_id = user_state[TEAM_ID];
  const sport = user_state[SPORT_TYPE];
  const tags = user_state['tags'];

  //Get the filename of the video
  const cleanFilename = filename.split(".")[0];
  if (typeof user_id !== "string")
    throw new Error(`User id is expected to be a string. Recieved ${user_id}`);
  if (!VALID_MATCH_TYPES.includes(game_type))
    throw new Error(`Invalid match type. Recieved ${game_type}`);

  const id = `${uuidv4().toUpperCase()}-WEBAPP`;
  const current_date = new Date().toISOString();

  let is_teams_upload = false;
  if (team_id !== null) {
    is_teams_upload = true;
  }

  const video_metadata = {
    id: id,
    game_type: game_type,
    visible: true,
    date_uploaded: current_date,
    has_court_keypoints: false,
    process_status: "UPLOADING",
    creatorUserID: user_id,
    filename: cleanFilename,
    title: cleanFilename,
    team_id: team_id,
    is_teams_upload: is_teams_upload,
    sport: sport,
    tags: tags
  };

  return video_metadata;
}

export async function userIsBlocked(user, state) {

  // HACK! Temporarily bypass userIsblocked for tennis and padel:
  if (["padel", "tennis"].includes(state[SPORT_TYPE])) {
    return false;
  }
  const supabase = await getSupabaseFromCognitoUser(user);
  const { data, error } = await supabase
    .from("User")
    .select()
    .eq("id", user.attributes.sub);

  const allowed = data[0].id != null && data[0].is_blocked === false;
  return !allowed;
}

export async function updateVideoMetaDataToUploaded(
  user,
  id,
  transcode
) {
  let video_metadata;

  if (transcode) {
    video_metadata = {
      process_status: READY_FOR_TRANSCODING,
    };
  } else {
    video_metadata = {
      process_status: UPLOADED,
    };
  }

  const supabase = await getSupabaseFromCognitoUser(user);

  const { data, error } = await supabase
    .from("VideoMetadata")
    .update(video_metadata)
    .eq("id", id)
    .select();
}

export async function getTeamsForUser(user) {
  const supabase = await getSupabaseFromCognitoUser(user);

  const { data, error } = await supabase
    .from("UserTeamLink")
    .select(
      `
                Team (id,
                name
                )
            `
    )
    .eq("user_id", user.attributes.sub);

  const teams = data.map((val) => ({ id: val.Team.id, name: val.Team.name }));
  return teams;
}
