import { DARK, LIGHT } from "../../../appConstants";

export const GAME_TYPE_LABEL = "Game Type";
export const SPORT_TYPE_LABEL = "Sport";
export const SELECT_TEAM_LABEL = "Private/Team upload"
export const MATCH_DATE_LABEL = "Match Date";
export const GAME_TYPE_ARIA_LABELS = {
    singles : "Singles Match, One versus One",
    doubles : "Doubles Match, Two versus Two",
}
export const GAME_TYPE_ICONS = {
    singles : "/singles-icon.png",
    doubles : "/doubles-icon.png",
}

export const SPORT_TYPE_ARIA_LABELS = {
    badminton : "Badminton",
    padel : "Padel",
    tennis: "Tennis"
}

export const SPORT_TYPE_ICONS = {
    badminton : "/sport_badminton.png",
    padel : "/sport_padel.png",
    tennis : "/sport_tennis.png"
}

export const ICON_REVERSE_PERCENTAGE_BY_MODE = {
    [DARK] : {true : 60, false : 40},
    [LIGHT] : {true : 60, false : 70},

}