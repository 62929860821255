import React from "react";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
import { HEADER_STYLE_PROPS, RIGHT_HEADER_PROPS, STYLE } from "./style";
import { ThemeToggle } from "./themeToggle";
import { APP_NAME } from "./constants";

export function Header({ signOut, user, colorMode, setColorMode }) {
    return (
        <Flex
            {...HEADER_STYLE_PROPS}
            style={STYLE}
            justifyContent='space-between'>
            <Flex {...RIGHT_HEADER_PROPS}>
                <Flex {...RIGHT_HEADER_PROPS} gap={5}>
                <Image src={"/webLogo.png"} height="1rem"/>
                <Text>
                    <b> {APP_NAME} </b>
                </Text>
                </Flex>
                <ThemeToggle
                    colorMode={colorMode}
                    setColorMode={setColorMode}
                />
            </Flex>
            <Flex {...HEADER_STYLE_PROPS} style={STYLE}>
                <Text variation='primary' isTruncated>
                    {user.attributes.email}
                </Text>
                <Text
                    onClick={signOut}
                    variation='primary'
                    as='span'
                    textDecoration={"underline"}
                    style={{ cursor: "pointer" }}>
                    Sign Out
                </Text>
            </Flex>
        </Flex>
    );
}
