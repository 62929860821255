import React, { useState, useMemo, useEffect } from "react";
import { Uploader } from "./Uploader/uploader";
import { Header } from "./Header/header";
import { SendButton } from "./Send/sendButton";
import { TranscodeCheckbox } from "./Uploader/TranscodeCheckbox/transcodeCheckbox";

import { VideoPreview } from "./Uploader/Preview/videoPreview";
import { DeleteVideo } from "./Uploader/DeleteVideo/deleteVideoButton";
import {Text} from "@aws-amplify/ui-react";


import {
    DATE,
    DISMISS_WRONG_ALERTS_AFTER,
    INITIAL_STATE,
    MATCH_TYPE,
    SPORT_TYPE,
    TEAM_ID,
    TEAM_LIST,
    VIDEO_URL,
} from "./Uploader/constants";
import { MetaDataSelector } from "./Uploader/MetaData/MetaDataSelector";
import { TextBelow } from "./Uploader/textBelow";
import { Alert } from "@aws-amplify/ui-react";
import { getTeamsForUser } from "../communication/supabase";

export function UploadPage({ signOut, user, colorMode, setColorMode }) {
    const [needsTranscoding, setNeedsTranscoding] = useState(false);
    const [matchDate, setMatchDate] = useState(INITIAL_STATE[DATE]);
    const [matchType, setMatchType] = useState(INITIAL_STATE[MATCH_TYPE]);
    const [sportType, setSportType] = useState(INITIAL_STATE[SPORT_TYPE]);
    const [tags, setTags] = useState(null);
    const [teamId, setTeamId] = useState(INITIAL_STATE[TEAM_ID]);
    const [teamList, setTeamList] = useState(INITIAL_STATE[TEAM_LIST]);
    const [files, setFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [alerts, setAlerts] = useState({});


    const formatTags = (text) => {
        // Convert to lowercase
        let formattedText = text.toLowerCase();

        // Replace any non tag-friendly characters with a dash
        formattedText = formattedText.replace(/[^a-z0-9-\s_]/g, '-');

        // Replace multiple spaces with a single space
        formattedText = formattedText.replace(/\s\s+/g, ' ');

        // Update the state with the formatted text
        if (!formattedText?.trim()?.length) {
            setTags(null);
        } else {
            setTags(formattedText);
        }
    };

    useEffect(() => {
        getTeamsForUser(user).then((teamList) => setTeamList(teamList));
    }, [user]);

    // Dissmiss alerts after a certain amount of time (each alert has its own timeout)
    useEffect(() => {
        Object.keys(alerts).forEach((key) => {
            if (alerts[key] && DISMISS_WRONG_ALERTS_AFTER > 0)
                setTimeout(
                    () =>
                        // Delete that key from the alerts object
                        setAlerts((alerts) => {
                            delete alerts[key];
                            return { ...alerts };
                        }),
                    DISMISS_WRONG_ALERTS_AFTER
                );
        });
    }, [alerts]);

    const state = useMemo(() => {
        return {
            [DATE]: matchDate,
            [MATCH_TYPE]: matchType,
            [SPORT_TYPE]: sportType,
            [TEAM_ID]: teamId,
            [VIDEO_URL]: files.length > 0 ? files : null,
            'tags': tags ? tags.split(' ') : null,
        };
    }, [matchDate, matchType, sportType, teamId, files, tags]);

    return (
        <>
            <Header
                signOut={signOut}
                user={user}
                colorMode={colorMode}
                setColorMode={setColorMode}
            />
            <MetaDataSelector
                matchType={matchType}
                setMatchType={setMatchType}
                sportType={sportType}
                setSportType={setSportType}
                matchDate={matchDate}
                setMatchDate={setMatchDate}
                teamList={teamList}
                teamId={teamId}
                setTeamId={setTeamId}
                colorMode={colorMode}
            />
            <div style={{
                marginLeft: 16,
                marginBottom: 20
            }}>
                <Text htmlFor="tags" style={{
                    color: 'white',
                    marginRight: 10,
                }}>Tags</Text>
                <Text style={{
                    color: 'white',
                    fontSize: 12,
                    marginTop: 10,
                    marginBottom: 6,
                }}>ℹ️ Tags are separated by spaces.
                </Text>
                <input
                    type="text"
                    placeholder="Add match tags"
                    value={tags ?? ''}
                    onChange={(e) => formatTags(e.target.value)}
                />
            </div>
            {files.length === 0 ? (
                <Uploader
                    files={files}
                    setFiles={setFiles}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
            ) : (
                <VideoPreview files={files} setFiles={setFiles} />
            )}

            {Object.keys(alerts).map((key) => {
                const { type, message } = alerts[key];
                return (
                    <Alert
                        key={key}
                        variation={type}
                        heading={message}
                        isDismissible={true}
                        hasIcon={false}
                        onDismiss={() => {
                            setAlerts((alerts) => {
                                delete alerts[key];
                                return { ...alerts };
                            });
                        }}
                    />
                );
            })}
            <TranscodeCheckbox
                needsTranscoding={needsTranscoding}
                setNeedsTranscoding={setNeedsTranscoding}
            />
            <SendButton
                state={state}
                user={user}
                files={files}
                needsTranscoding={needsTranscoding}
                setFiles={setFiles}
                setIsUploading={setIsUploading}
                setMatchType={setMatchType}
            />
            {files.length > 0 && !isUploading ? (
                <DeleteVideo
                    files={files}
                    setFiles={setFiles}
                    isUploading={isUploading}
                />
            ) : (
                <TextBelow />
            )}
        </>
    );
}
