// import awsExports from "../aws-exports";
// import { UserPoolPublicKeys } from './constants.js';
// import jwkToPemPkg from 'jwk-to-pem';
// const jwkToPem = jwkToPemPkg;
import * as jose from 'jose'

// function parseJwt(token) {
//     return {
//         "header": JSON.parse(Buffer.from(token.split('.')[0], 'base64').toString()),
//         "payload": JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
//     }
// }

// function selectPublicKey(token) {
//     const decodedToken = parseJwt(token)
//     var publicKey = UserPoolPublicKeys.find(key => {
//         return key.kid === decodedToken.header.kid
//     })
//     return publicKey
// }

// function verifyAccessToken(token) {
//     const jwkPublicKey = selectPublicKey(token)

//     //convert JWK keys to PEM format
//     const pemPublicKey = jwkToPem(jwkPublicKey);

//     // verify id_token signature
//     var verified = jwt.verify(token, pemPublicKey, {
//         algorithms: [jwkPublicKey.alg],
//         aud: awsExports.aws_user_pools_web_client_id,
//         iss: `https://cognito-idp.${awsExports.aws_cognito_region}.amazonaws.com/${awsExports.aws_user_pools_id}`,
//         token_use: "access",
//     });

//     return verified;
// }

export function getAccessToken(user) {
    const accessToken = user.signInUserSession.accessToken.jwtToken
    const claims = jose.decodeJwt(accessToken)
    return claims
}