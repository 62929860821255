export const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "0.5rem",
    marginBottom: "0.1rem",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 4,
    borderColor: "#eeeeee",
    backgroundColor : "hsla(255, 100%, 100%, 0.2)",
    borderStyle: "dashed",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

export const focusedStyle = {
    borderColor: "#2196f3",
};

export const acceptStyle = {
    borderColor: "#00e676",
};

export const rejectStyle = {
    borderColor: "#ff1744",
};
