import { BulkPreview } from "./bulkPreview";
import { VideoPlayer } from "./videoPlayer";

export function VideoPreview({ files, setFiles }) {
    // Check all files contain the preview property
    if (!files.every((file) => file.preview))
        throw new Error("All files must contain a preview property");
    let preview = null;
    if (files.length === 1) {
        preview = files.map((file) => (
            <VideoPlayer key={file.name} file={file} files={files} setFiles={setFiles}/>
        ));
    } else if (files.length > 1) {
        preview = <BulkPreview files={files} setFiles={setFiles} />;
    }

    return preview;
}
