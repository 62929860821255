
export const DEFAULT_TYPE_NAME = "VideoMetadata";
export const DEFAULT_VISIBILITY = true;
export const DEFAULT_HAS_COURT_KEYPOINTS = false;
export const DEFAULT_GAME_SUMMARY_KEY = "";
export const DEFAULT_GAME_SUMMARY_URL = "";
export const TRANSCODING_CHECKBOX_MESSAGE = "Needs transcoding (Only check this box if the match has been downloaded using CocoCut or screen recorded)";

export const UPLOADING = "UPLOADING";
export const UPLOADED = "UPLOADED";
export const UPLOAD_FAILED = "UPLOAD_FAILED";
export const READY_FOR_TRANSCODING = "READY_FOR_TRANSCODING";

export const ID = 'id';
export const CREATED_AT = 'createdAt';
export const UPDATED_AT = 'updatedAt';
export const DATE_PROCESSED = 'date_processed'
export const DATE_UPLOADED = 'date_uploaded';
export const HAS_COURT_KEYPOINTS = 'has_court_keypoints';
export const OWNER = 'owner';
export const VISIBLE = 'visible';
export const PROCESS_STATUS = 'process_status';
export const LAST_CHANGED_AT = '_lastChangedAt';
export const VERSION = '_version';
export const TYPE_NAME = '__typename'
export const GAME_TYPE = 'game_type';

export const PLAYER_IDS = 'playerIDs';
export const CREATOR_USER_ID = 'creatorUserID';
export const GAME_SUMMARY_KEY = 'game_summary_key';
export const GAME_SUMMARY_URL = 'game_summary_url';
export const FILENAME = 'filename';

export const UserPoolPublicKeys = [{ "alg": "RS256", "e": "AQAB", "kid": "1SpSqwopU9klMM3/sEJT0Fs59GpY34ru7dwVK0gzCXs=", "kty": "RSA", "n": "oruEgbyjQg0niYY4T4QrNyvtJ8-XsnMqkuMEw09DON8eTFr7zUwae84HGyEMaDodmMpxIQT9_3avs4DSFkFnoUfKQQy4uQ6RrE_2OMlnBuwGzPLTScpSvWbQbgrLQIIrhRq8PFQVQY0S-jsWP3txtEnQkQyz4TWAJVV7uib4x8sGNLd5A38saoWg1KCBnnFng0bAzf7blzirXrsH68NxG8p5K3npSeBRcLJkVslU8IM08GDFpekoydacSYs-8RNHKcJBvrMgCdz2-zjbax0XR4-bhRQPJDRmwcaXa-ibKfvN03RMapF4rEvmHUx99ic7aX750ltoj0-bJQb_920s8Q", "use": "sig" }, { "alg": "RS256", "e": "AQAB", "kid": "tqX5R2H2fkV7DjPxiEMNnAJ714OhKs5c387nDSANTX4=", "kty": "RSA", "n": "sNH3C4cvAekPXVF5ybaw90HDJ1vHJdf7I_6Xacao1OErJg6jnbExDQX5G7VAHNOl8MCHKe_ZfYyD7pJA1rT5mpE_pqtM_ZapIApBtn8Frg7ZBhEPcma1I-8tosxAgJO-C-mJAzEY88wfJP6VhBVtFzEnzXjAGqSsjPPDd4wBCynbHoOr9APt2EXYXo4LSYxTWW66q53PmDFcI7xBszM6Vkextk70gjBWRVNxxEv0W5Y57bTbA0AvI4ICjclpM4wcJGwQPH0DRtERm8ECYvRdT0bgamuhzvrI7xAx6u7mfpCdYTRS2QAn3gR5WpoMzGOoWeMM0bhfTDiB_oZ8V8r7Fw", "use": "sig" }];

export const SupabaseJWTSignKey = "KWW8Ck06R5siUTm8jrCwbVlViRUrnn6rHhSXIh8KlEJvgh6ydqIHaDiewyt4XJXygeiRgfYUQB3N21pqHr0Mvw=="
export const publicSupabaseUrl = "https://knozyjwzeforivzthwyu.supabase.co"
export const publicSupabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imtub3p5and6ZWZvcml2enRod3l1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzU3NzkyMjQsImV4cCI6MTk5MTM1NTIyNH0.K78FLafNv4QIj_QT-ZUMLsOTgajg4XbqAqUOl2jD18U"
