import React from 'react'
import { Button, useTheme } from "@aws-amplify/ui-react";
import { DELETE_VIDEO_TEXT } from '../constants';
import { cleanVideo } from '../../utils';

export function DeleteVideo({files, setFiles, isUploading}){
    const { tokens } = useTheme();
    return (
        <Button
            isFullWidth={true}
            variation="link"
            color={tokens.colors.font.primary}
            ariaLabel="Delete this video to upload a new one"
            onClick={() => cleanVideo(files, setFiles, false)}
            isLoading={isUploading}
            >
            {`${DELETE_VIDEO_TEXT}${files.length > 1? "s" : ""}`}
        </Button>
    );
}

