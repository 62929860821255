import React from "react";
import {
    GUIDELINES_TITLE,
    GUIDELINES_HREF,
    GUIDELINES_HREF_TEXT,
    GUIDELINES_PRETEXT,
    INSTRUCTIONS_TITLE,
    INSTRUCTIONS_TEXT,
    GUIDELINES,
} from "./constants";
import { Link, Text } from "@aws-amplify/ui-react";

const WRAPPER_TAG = "div";

export function TextBelow() {
    //Text wrappers are divs
    return ( <>
        <Text marginTop={5} as={WRAPPER_TAG}>
            <h4>{GUIDELINES_TITLE}</h4>
            <Text marginTop={-15}>
            {GUIDELINES_PRETEXT}
            <Link href={GUIDELINES_HREF}>{` ${GUIDELINES_HREF_TEXT}`}</Link>.
            {/*Plot a list of bullet points*/}
            <ul>
                {GUIDELINES.map((instruction, index) => (
                    <li key={`guideline-${index}`}>{instruction}</li>
                ))}
            </ul>
            </Text>
        </Text>

        <Text as={WRAPPER_TAG}>
            <h4>{INSTRUCTIONS_TITLE}</h4>
            <Text marginTop={-15}>{INSTRUCTIONS_TEXT}</Text>
        </Text>
        </>
    );
}
