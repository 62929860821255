import { Card, Flex, Grid, Text } from "@aws-amplify/ui-react";
import React from "react";
import { SPORT_TYPE_LABEL, GAME_TYPE_LABEL, SELECT_TEAM_LABEL } from "./constants";
import { SingleDoublesButton } from "./MatchType/singlesDoublesButton";
import { FORM_FLEX_FIELDS } from "./style";
import { TeamSelector } from "./SelectTeam/TeamSelector";
import { SportSelectorButton } from "./SportType/sportSelectorButton";

export function MetaDataSelector({
    matchType,
    setMatchType,
    sportType,
    setSportType,
    matchDate,
    setMatchDate,
    teamList,
    teamId,
    setTeamId,
    colorMode
}) {


    return (
        <Grid
            templateColumns={{ base: "1fr", medium: "1fr 1fr", large: "1fr 1fr 1fr", xlarge: "1fr 1fr 1fr 1fr" }}
            templateRows={{
                base: "repeat(2, 3rem)",
                medium: "repeat(1, 4rem)",
            }}
            alignItems="center"
            gap={0}
        > 
        <Card
        columnStart="1"
        columnEnd="-1">
            <Flex {...FORM_FLEX_FIELDS}>
                <Text>{SPORT_TYPE_LABEL}: </Text>
                <SportSelectorButton
                    sportType={sportType}
                    setSportType={setSportType}
                    colorMode={colorMode}
                />
            </Flex>
        </Card>
            <Card
            columnStart="1"
            columnEnd="-1">
                <Flex {...FORM_FLEX_FIELDS}>
                    <Text>{GAME_TYPE_LABEL}: </Text>
                    <SingleDoublesButton
                        matchType={matchType}
                        setMatchType={setMatchType}
                        colorMode={colorMode}
                    />
                </Flex>
            </Card>
            <Card
            columnStart="1"
            columnEnd="-1">
                <Flex {...FORM_FLEX_FIELDS}>
                    <Text>{SELECT_TEAM_LABEL}: </Text>
                    <TeamSelector teams={teamList}
                    teamId={teamId}
                    setTeamId={setTeamId}/>
                </Flex>
            </Card>
            {/* // Uncomment it when it will make sense that the user will be able to change the match date
            <Card columnSpan={{base: 1, medium:-1}}>
                <Flex {...FORM_FLEX_FIELDS}>
                <Text>{MATCH_DATE_LABEL}: </Text>
                <MatchDate date={matchDate} setDate={setMatchDate} />
                </Flex>
            </Card>
            */}
        </Grid>
    );
}
