import React from "react";
import { CheckboxField } from '@aws-amplify/ui-react';
import { TRANSCODING_CHECKBOX_MESSAGE } from "../../../communication/constants";


export function TranscodeCheckbox({needsTranscoding, setNeedsTranscoding}) {
      return (
        <CheckboxField
          name="needs-transcoding"
          value={needsTranscoding}
          checked={needsTranscoding}
          onChange={(newValue) => setNeedsTranscoding(newValue.target.checked)}
          label={TRANSCODING_CHECKBOX_MESSAGE}
        />
      );
    };

